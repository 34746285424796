<template>
  <layout>
    <template #header>
      <inqli-header />
    </template>
    <feed-layout v-model="isQuestionDetailsShowing">
      <template v-slot:list>
        <h3 class="list-title mt-2">My Questions</h3>
        <div class="mt-3">
          <toggle-button-group
            v-model="currentListIndex"
            :options="listViewOptions"
            tile
            group
            spaceBetween="mx-9"
          />
        </div>
        <div v-if="currentList.list.length > 0">
          <question-card
            v-for="(item, index) in currentList.list"
            :key="item.question.id"
            :question="item.question"
            :author="item.author"
            :metadata="item.metadata"
            @click="showQuestion(index, $event)"
            class="question-card"
            :closeCard="closeCard(index)"
          />
          <div class="pagination">
            <pagination
              @update="updateCurrentList"
              :totalPages="currentList.totalPages"
              :currentPage="currentList.currentPage"
            />
          </div>
        </div>
        <div v-else class="empty-list-placeholder">
          <img class="placeholder-image" :src="placeholderContent.image" />
          <h4 class="mt-2">
            {{ placeholderContent.text }}
          </h4>
          <inqli-button
            class="mt-4"
            v-if="placeholderContent.buttonText"
            @click="placeholderContent.buttonFunction"
            >{{ placeholderContent.buttonText }}</inqli-button
          >
        </div>
      </template>
      <template>
        <div class="question-detail-container">
          <question-detail
            v-if="isQuestionDetailsShowing"
            :key="currentQuestionDetailKey"
            :questionDetail="currentQuestionDetail"
            :closeCard="closeQuestionDetail"
            :isLoadingQuestionDetail="isLoadingQuestionDetail"
            @bookmark:delete="
              getBookmarkedQuestionList(bookmarkedQuestionList.currentPage)
            "
            @bookmark:add="
              getBookmarkedQuestionList(bookmarkedQuestionList.currentPage)
            "
          />
        </div>
      </template>
    </feed-layout>
  </layout>
</template>
<script>
import DefaultLayout from "../app/components/layouts/DefaultLayout.vue";
import Header, { EventBus } from "../app/components/headers/DefaultHeader";
import FeedLayout from "./components/FeedLayout.vue";
import QuestionCard from "./components/question-card/QuestionCard.vue";
import ToggleButtonGroup from "../app/components/buttons/ToggleButtonGroup.vue";
import Pagination from "../app/components/Pagination.vue";
import QuestionDetail from "./components/question-detail/QuestionDetail.vue";
import Button from "../app/components/buttons/Button.vue";

import {
  getUserQuestion,
  getQuestionDetails,
  unBookmarkQuestion,
} from "./utils/question-request";

export default {
  name: "MyQuestion",
  metaInfo: {
    title: "My Question",
  },
  components: {
    layout: DefaultLayout,
    inqliHeader: Header,
    "feed-layout": FeedLayout,
    "question-card": QuestionCard,
    "toggle-button-group": ToggleButtonGroup,
    pagination: Pagination,
    "question-detail": QuestionDetail,
    "inqli-button": Button,
  },
  data() {
    return {
      limit: 10,
      bookmarkedQuestionList: {
        list: [],
        currentPage: 1,
        totalPages: 1,
      },
      askedQuestionList: {
        list: [],
        currentPage: 1,
        totalPages: 1,
      },
      repliedQuestionList: {
        list: [],
        currentPage: 1,
        totalPages: 1,
      },
      currentListIndex: 0,
      currentQuestionDetailIndex: 0,
      isLoadingQuestionDetail: false,
      isQuestionDetailsShowing: false,
      currentQuestion: {},
    };
  },
  computed: {
    closeCard() {
      if (this.currentListIndex === 0) {
        // Assign to avoid changing variable scope of `this` in the wrapper function
        const removeBookmarkQuestion = this.removeBookmarkQuestion;
        return (index) => {
          // return another wrapper function to avoid executing removeBookQuestion method
          return () => {
            removeBookmarkQuestion(index);
          };
        };
      }
      return () => {};
    },
    placeholderContent() {
      return [
        {
          image: require("../app/assets/bookmark-question.svg"),
          text: "When you swipe right on a matched question, it will show up here.",
          buttonText: "Matched questions",
          buttonFunction: this.goToMatchedQuestions,
        },
        {
          image: require("../app/assets/ask-question.svg"),
          text: "Questions that you have asked will show up here.",
          buttonText: "Ask a question",
          buttonFunction: this.openQuestionForm,
        },
        {
          image: require("../app/assets/reply-question.svg"),
          text: "Questions you have replied to will show up here.",
        },
      ][this.currentListIndex];
    },
    listViewOptions() {
      return [
        {
          label: "Bookmarked",
          value: 0,
        },
        {
          label: "Asked",
          value: 1,
        },
        {
          label: "Replied",
          value: 2,
        },
      ];
    },
    currentUserId() {
      return this.$store.state.user.username;
    },
    currentList() {
      return [
        this.bookmarkedQuestionList,
        this.askedQuestionList,
        this.repliedQuestionList,
      ][this.currentListIndex];
    },
    currentQuestionDetail() {
      return {
        ...this.currentList.list[this.currentQuestionDetailIndex],
        ...this.currentQuestion,
      };
    },
    currentQuestionDetailKey() {
      if (!this.currentQuestionDetail) return 0;
      if (this.currentQuestionDetail) {
        const isLiked = this.currentQuestionDetail.is_liked;
        return this.currentQuestionDetail.question.id + isLiked;
      }
      return this.currentQuestionDetail.question.id;
    },
  },
  async mounted() {
    await this.$store.dispatch("checkUser");
    const startPage = 1;
    await this.getBookmarkedQuestionList(startPage);
    this.getAskedQuestionList(startPage);
    this.getRepliedQuestionList(startPage);
  },
  methods: {
    openQuestionForm() {
      EventBus.$emit("open-ask-question");
    },
    goToMatchedQuestions() {
      this.$router.push("/");
    },
    showQuestion(questionIndex) {
      this.currentQuestionDetailIndex = questionIndex;
      this.isQuestionDetailsShowing = true;
      const questionId = this.currentList.list[questionIndex].question.id;
      this.currentQuestion = {};
      this.getQuestionDetail(questionId);
    },
    closeQuestionDetail() {
      this.isQuestionDetailsShowing = false;
    },
    async updateCurrentList(page) {
      this.isQuestionDetailsShowing = false;
      switch (this.currentListIndex) {
        case 0:
          await this.getBookmarkedQuestionList(page);
          break;
        case 1:
          await this.getAskedQuestionList(page);
          break;
        case 2:
          await this.getRepliedQuestionList(page);
          break;
      }
    },
    async getBookmarkedQuestionList(page) {
      try {
        const data = await getUserQuestion({
          userId: this.currentUserId,
          relationship: "bookmarked",
          limit: this.limit,
          currentPage: page,
        });
        this.bookmarkedQuestionList = {
          list: data.list,
          totalPages: data.total_pages,
          currentPage: page,
        };
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the bookmarked questions."
        );
      }
    },
    async getAskedQuestionList(page) {
      try {
        const data = await getUserQuestion({
          userId: this.currentUserId,
          relationship: "asked",
          limit: this.limit,
          currentPage: page,
        });
        this.askedQuestionList = {
          list: data.list,
          totalPages: data.total_pages,
          currentPage: page,
        };
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the asked questions."
        );
      }
    },
    async getRepliedQuestionList(page) {
      try {
        const data = await getUserQuestion({
          userId: this.currentUserId,
          relationship: "replied",
          limit: this.limit,
          currentPage: page,
        });
        this.repliedQuestionList = {
          list: data.list,
          totalPages: data.total_pages,
          currentPage: page,
        };
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the replied questions."
        );
      }
    },
    async getQuestionDetail(questionId) {
      try {
        this.isLoadingQuestionDetail = true;
        const data = await getQuestionDetails(questionId);
        this.currentQuestion = data;
        this.isLoadingQuestionDetail = false;
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while getting the question details."
        );
      }
    },
    async removeBookmarkQuestion(index) {
      const question = this.bookmarkedQuestionList.list[index];
      try {
        const questionId = question.question.id;
        await unBookmarkQuestion({ userId: this.currentUserId, questionId });
      } catch (e) {
        this.$store.dispatch(
          "callAlert",
          "An error occurred while removing the bookmark."
        );
      }
      // refresh the list
      this.getBookmarkedQuestionList(this.bookmarkedQuestionList.currentPage);
    },
  },
};
</script>
<style lang="scss" scoped>
.question-card {
  margin-bottom: 12px;
}
.pagination {
  width: 240px;
  margin: auto;
  margin-top: 30px;
}
.empty-list-placeholder {
  margin: 16px auto;
  .placeholder-image {
    max-width: 250px;
    margin: auto;
  }
}
</style>
