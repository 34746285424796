var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "layout",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [_c("inqli-header")]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "feed-layout",
        {
          scopedSlots: _vm._u([
            {
              key: "list",
              fn: function() {
                return [
                  _c("h3", { staticClass: "list-title mt-2" }, [
                    _vm._v("My Questions")
                  ]),
                  _c(
                    "div",
                    { staticClass: "mt-3" },
                    [
                      _c("toggle-button-group", {
                        attrs: {
                          options: _vm.listViewOptions,
                          tile: "",
                          group: "",
                          spaceBetween: "mx-9"
                        },
                        model: {
                          value: _vm.currentListIndex,
                          callback: function($$v) {
                            _vm.currentListIndex = $$v
                          },
                          expression: "currentListIndex"
                        }
                      })
                    ],
                    1
                  ),
                  _vm.currentList.list.length > 0
                    ? _c(
                        "div",
                        [
                          _vm._l(_vm.currentList.list, function(item, index) {
                            return _c("question-card", {
                              key: item.question.id,
                              staticClass: "question-card",
                              attrs: {
                                question: item.question,
                                author: item.author,
                                metadata: item.metadata,
                                closeCard: _vm.closeCard(index)
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showQuestion(index, $event)
                                }
                              }
                            })
                          }),
                          _c(
                            "div",
                            { staticClass: "pagination" },
                            [
                              _c("pagination", {
                                attrs: {
                                  totalPages: _vm.currentList.totalPages,
                                  currentPage: _vm.currentList.currentPage
                                },
                                on: { update: _vm.updateCurrentList }
                              })
                            ],
                            1
                          )
                        ],
                        2
                      )
                    : _c(
                        "div",
                        { staticClass: "empty-list-placeholder" },
                        [
                          _c("img", {
                            staticClass: "placeholder-image",
                            attrs: { src: _vm.placeholderContent.image }
                          }),
                          _c("h4", { staticClass: "mt-2" }, [
                            _vm._v(
                              " " + _vm._s(_vm.placeholderContent.text) + " "
                            )
                          ]),
                          _vm.placeholderContent.buttonText
                            ? _c(
                                "inqli-button",
                                {
                                  staticClass: "mt-4",
                                  on: {
                                    click: _vm.placeholderContent.buttonFunction
                                  }
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.placeholderContent.buttonText)
                                  )
                                ]
                              )
                            : _vm._e()
                        ],
                        1
                      )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.isQuestionDetailsShowing,
            callback: function($$v) {
              _vm.isQuestionDetailsShowing = $$v
            },
            expression: "isQuestionDetailsShowing"
          }
        },
        [
          [
            _c(
              "div",
              { staticClass: "question-detail-container" },
              [
                _vm.isQuestionDetailsShowing
                  ? _c("question-detail", {
                      key: _vm.currentQuestionDetailKey,
                      attrs: {
                        questionDetail: _vm.currentQuestionDetail,
                        closeCard: _vm.closeQuestionDetail,
                        isLoadingQuestionDetail: _vm.isLoadingQuestionDetail
                      },
                      on: {
                        "bookmark:delete": function($event) {
                          return _vm.getBookmarkedQuestionList(
                            _vm.bookmarkedQuestionList.currentPage
                          )
                        },
                        "bookmark:add": function($event) {
                          return _vm.getBookmarkedQuestionList(
                            _vm.bookmarkedQuestionList.currentPage
                          )
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ]
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }